import {
  useState,
  useEffect,
  FC,
  KeyboardEvent,
  MouseEvent,
  useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleBarReact from "simplebar-react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ListItem, Drawer } from "@mui/material";
import routesPaths from "routes/routesPaths";
import { useMediaQuery } from "helpers/hooks";
import { SquereButton, MainButton } from "components/Buttons";
import { LinkAccountModal } from "components/Modals";
import Navigation from "./components/Navigation";
import UserMenu from "./components/UserMenu";
import { AppText, Language, Switch } from "components";
import { mainNav } from "./components/authNav";
import {
  HeaderWrapper,
  Content,
  LogoLink,
  Logo,
  SeparateContent,
  LogoSeparateContent,
  HeaderContentContainer,
  SideBarLink,
  SideBarLinkContent,
  StyledNavList,
  SidebarButtons,
  StyledBox,
  AdminLoginContainer,
  BackLink,
} from "./styled";
import { colors, siteName } from "helpers/consts";
import { UserStore, GlobalStore, WalletStore } from "stores";
import { companyLogIn } from "api/company";
import { checkIsLogged } from "api/user";

import logoImg from "assets/fnt logo.svg";
import devLogoImg from "assets/fntlogowhite.jpg";
import { ReactComponent as CartIcon } from "assets/icons/cart.svg";
import { ReactComponent as MenuIcon } from "assets/icons/menu.svg";
import { ReactComponent as ArrowIcon } from "assets/icons/chevron-right.svg";
import { ReactComponent as BackIcon } from "assets/icons/arrow-narrow-left.svg";

const Header: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    isAuthenticated,
    isLoggedAsAdmin,
    setIsLoggedAsAdmin,
    user,
    userCompanyInfo,
    isLoggedFromTelegram,
  } = UserStore;
  const { walletsList } = WalletStore;
  const { lang } = GlobalStore;

  const mobile = useMediaQuery("(min-width: 38em)");
  const desktop = useMediaQuery("(min-width: 54em)");

  const [isNavOpen, changeIsNavOpen] = useState(false);
  const [isLinkWebAccountOpen, setIsLinkWebAccountOpen] =
    useState<boolean>(false);

  const isByBit = useMemo<boolean>(
    () => location.pathname.includes("futures"),
    [location]
  );

  const hasUserAnyBalance = useMemo<boolean>(
    () => !!walletsList?.find(({ balance }) => Number(balance) > 0),
    [walletsList]
  );

  const toggleDrawer =
    (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === "keydown" &&
        (event as KeyboardEvent).key !== "Escape"
      ) {
        return;
      }

      changeIsNavOpen(open);
    };

  useEffect(() => {
    isNavOpen && desktop && changeIsNavOpen(false);
  }, [desktop, isNavOpen]);

  useEffect(() => {
    if (isAuthenticated && !isLoggedAsAdmin) {
      const isAdminLogged = localStorage.getItem("isLoggedAsAdmin");
      if (isAdminLogged) {
        setIsLoggedAsAdmin();
      }
    }
  }, [isAuthenticated, isLoggedAsAdmin, setIsLoggedAsAdmin]);

  useEffect(() => {
    if (isNavOpen) {
      changeIsNavOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <HeaderWrapper $isDarkTheme={isByBit}>
      <HeaderContentContainer $isDarkTheme={isByBit}>
        {isLoggedAsAdmin && (
          <AdminLoginContainer>
            <AppText>{t("LOGGED_FROM_ADMIN_PANEL")}</AppText>
          </AdminLoginContainer>
        )}

        <Content $isDarkTheme={isByBit}>
          <LogoSeparateContent>
            {isByBit ? (
              <BackLink
                to={isAuthenticated ? routesPaths.dashboard : routesPaths.login}
              >
                <BackIcon />
                Back to {siteName}
              </BackLink>
            ) : (
              <LogoLink
                to={isAuthenticated ? routesPaths.dashboard : routesPaths.login}
              >
                <Logo
                  src={
                    process.env.REACT_APP_ENVIRONMENT !== "dev"
                      ? logoImg
                      : devLogoImg
                  }
                />
              </LogoLink>
            )}
          </LogoSeparateContent>

          <SeparateContent $isByBit={isByBit}>
            {isAuthenticated && isByBit && mobile && <Navigation isByBit />}

            {isAuthenticated &&
              !isByBit &&
              mobile &&
              (user.type === "COMPANY_USER" || user.type === "COMPANY") && (
                <Switch
                  checked={user.type === "COMPANY"}
                  onChange={() =>
                    user.type === "COMPANY" ? checkIsLogged() : companyLogIn()
                  }
                  label={
                    userCompanyInfo?.name ? userCompanyInfo.name : t("COMPANY")
                  }
                  inHeader
                  maxWidth={200}
                />
              )}

            {isAuthenticated &&
              desktop &&
              !isLoggedFromTelegram &&
              !isByBit && (
                <MainButton
                  onClick={() => navigate(routesPaths.cryptoTransfers)}
                  maxHeight="4rem"
                  style={{ fontSize: lang === "en" ? "1.4rem" : "1.2rem" }}
                >
                  <CartIcon />
                  {t("BUY_SELL_CRYPTO")}
                </MainButton>
              )}

            {!isByBit && (
              <UserMenu
                setIsLinkWebAccountOpen={setIsLinkWebAccountOpen}
                hasUserAnyBalance={hasUserAnyBalance}
              />
            )}

            <Language isDarkTheme={isByBit} />
            {isAuthenticated && !desktop && !isByBit && (
              <SquereButton onClick={() => changeIsNavOpen(true)}>
                <MenuIcon />
              </SquereButton>
            )}
          </SeparateContent>
        </Content>
        {desktop && !isByBit && <Navigation />}
      </HeaderContentContainer>

      {isAuthenticated && (
        <Drawer anchor="left" open={isNavOpen} onClose={toggleDrawer(false)}>
          <SimpleBarReact
            style={{ width: "31rem", height: "100dvh", overflowX: "hidden" }}
            tabIndex={-1}
            forceVisible="y"
          >
            <LogoLink to={routesPaths.dashboard} $isSidebar>
              <Logo
                src={
                  process.env.REACT_APP_ENVIRONMENT !== "dev"
                    ? logoImg
                    : devLogoImg
                }
              />
            </LogoLink>

            <StyledBox
              sx={{
                width: 310,
              }}
              role="presentation"
              onKeyDown={toggleDrawer(false)}
            >
              <StyledNavList>
                {mainNav.map(({ path, key, label, icon }) => {
                  return (
                    <ListItem key={key} disablePadding>
                      <SideBarLink to={path} onClick={toggleDrawer(false)}>
                        <SideBarLinkContent>
                          {icon}{" "}
                          <AppText fontWeight={600} color={colors.gray_600}>
                            {t(label)}
                          </AppText>
                        </SideBarLinkContent>
                        <ArrowIcon />
                      </SideBarLink>
                    </ListItem>
                  );
                })}
              </StyledNavList>

              <SidebarButtons>
                <UserMenu
                  isInSidebar
                  setIsLinkWebAccountOpen={setIsLinkWebAccountOpen}
                  hasUserAnyBalance={hasUserAnyBalance}
                />
                <Language />
                {!mobile &&
                  (user.type === "COMPANY_USER" || user.type === "COMPANY") && (
                    <Switch
                      checked={user.type === "COMPANY"}
                      onChange={() =>
                        user.type === "COMPANY"
                          ? checkIsLogged()
                          : companyLogIn()
                      }
                      label={
                        userCompanyInfo?.name
                          ? userCompanyInfo.name
                          : t("COMPANY")
                      }
                      style={{ width: "100%", justifyContent: "center" }}
                      inHeader
                      maxWidth={200}
                    />
                  )}
                {!desktop && !isLoggedFromTelegram && (
                  <MainButton
                    onClick={() => navigate(routesPaths.cryptoTransfers)}
                    fullWidth
                  >
                    <CartIcon />
                    {t("BUY_SELL_CRYPTO")}
                  </MainButton>
                )}
              </SidebarButtons>
            </StyledBox>
          </SimpleBarReact>
        </Drawer>
      )}
      {isLoggedFromTelegram &&
        user.email.includes("telegram") &&
        !hasUserAnyBalance && (
          <LinkAccountModal
            isOpen={isLinkWebAccountOpen}
            onClose={() => setIsLinkWebAccountOpen(false)}
          />
        )}
    </HeaderWrapper>
  );
};

export default observer(Header);
